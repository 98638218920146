import Chart from "chart.js/auto";
import { reloadChart, CHART_TOOLTIP, CHART_GRID, chartTicks } from "../../vendors/chart";
import { COLORS, cssVar } from "../../utils";

const CHART_WRAPPER = document.getElementById("chart-user-acquisition");
let userAcquisitionChart;

export const userAcquisition = () => {
	//---------------------------------------------------------
	// Chart
	//---------------------------------------------------------
	const BAR_COLOR = cssVar("--bs-chart-fill-green");

	const CHART_DATA = {
		labels: ["10/10", "11/10", "12/10", "13/10", "14/10", "15/10", "16/10"],
		datasets: [
			{
				label: "Organic Search",
				data: [13, 23, 30, 8, 13, 27, 54],
				backgroundColor: `rgb(${BAR_COLOR})`,
				borderRadius: 5,
				hoverOffset: 0,
			},
			{
				label: "Paid Search",
				data: [25, 20, 20, 40, 32, 10, 20],
				backgroundColor: `rgba(${BAR_COLOR}, 0.7)`,
				borderRadius: 5,
				hoverOffset: 0,
			},
			{
				label: "Direct",
				data: [20, 45, 20, 28, 10, 50, 45],
				backgroundColor: `rgba(${BAR_COLOR}, 0.45)`,
				borderRadius: 5,
				hoverOffset: 0,
			},
			{
				label: "Others",
				data: [10, 20, 35, 40, 12, 30, 18],
				backgroundColor: `rgba(${BAR_COLOR}, 0.2)`,
				borderRadius: 5,
				hoverOffset: 0,
			},
		],
	};

	const CHART_CONFIG = {
		type: "bar",
		data: CHART_DATA,
		options: {
			maintainAspectRatio: false,
			layout: {
				padding: {
					left: -1,
					bottom: 0,
				},
			},
			elements: {
				bar: {
					borderWidth: 0,
				},
			},
			responsive: true,
			plugins: {
				legend: {
					display: false,
				},
				title: {
					display: false,
				},
				tooltip: {
					...CHART_TOOLTIP,
					callbacks: {
						label: (tooltipItem, data) => {
							return tooltipItem.formattedValue + "K";
						},
					},
				},
			},
			scales: {
				x: {
					border: {
						display: false,
					},
					grid: {
						display: false,
					},
					ticks: {
						...chartTicks(2, cssVar("--bs-chart-ticks-green")),
					},
					stacked: true,
				},
				y: {
					border: {
						display: false,
					},
					grid: {
						color: cssVar("--bs-chart-grid-green"),
						tickLength: 0,
					},
					ticks: {
						...chartTicks(2, cssVar("--bs-chart-ticks-green")),
					},
					stacked: true,
				},
			},
		},
	};

	if (CHART_WRAPPER) {
		userAcquisitionChart = new Chart(CHART_WRAPPER, CHART_CONFIG);
	}

	//---------------------------------------------------------
	// Data
	//---------------------------------------------------------
	let list = "";
	const WRAPPER = document.getElementById("list-user-acquisition");
	const DATA = [
		{
			source: "Organic Search",
			visits: 243.2,
			percentage: 32.4,
			up: true,
		},
		{
			source: "Paid Search",
			visits: 142.5,
			percentage: 66.1,
			up: true,
		},
		{
			source: "Direct Traffic",
			visits: 78.2,
			percentage: 34.9,
			up: false,
		},
		{
			source: "Referral Traffic",
			visits: 65.9,
			percentage: 23.1,
			up: true,
		},
		{
			source: "Social Media",
			visits: 48.9,
			percentage: 52.5,
			up: true,
		},
		{
			source: "Others",
			visits: 32.5,
			percentage: 19.1,
			up: false,
		},
	];

	if (WRAPPER) {
		DATA.forEach((item) => {
			list += `<div class="d-flex align-items-start flex-wrap">
						<div class="flex-grow-1">${item.source}</div>

						<div class="fs-5 fw-medium text-end w-14">${item.visits}</div>

						<div class="fs-7 ms-4 d-none d-sm-flex align-items-center justify-content-end w-14 ${item.up ? "text-success" : "text-danger"}">
							${item.percentage}%

							<i class="ph fs-5 ms-1 ${item.up ? "ph-arrow-circle-up" : "ph-arrow-circle-down"}"></i>
						</div>

						<div class="progress w-100 my-3 h-0.5" role="progressbar" aria-valuenow="${item.percentage}" aria-valuemin="0" aria-valuemax="100">
							<div class="progress-bar bg-teal" style="width: ${item.percentage}%"></div>
						</div>
                    </div>`;

			WRAPPER.innerHTML = list;
		});
	}
};

// Reload chart to match dark/light mode when switched
// This function will be used in `colorMode.js`
export const userAcquisitionChartUpdate = () => {
	if (CHART_WRAPPER) {
		userAcquisitionChart.data.datasets[0].backgroundColor = `rgb(${cssVar("--bs-chart-fill-green")})`;
		userAcquisitionChart.data.datasets[1].backgroundColor = `rgba(${cssVar("--bs-chart-fill-green")}, 0.7)`;
		userAcquisitionChart.data.datasets[2].backgroundColor = `rgba(${cssVar("--bs-chart-fill-green")}, 0.45)`;
		userAcquisitionChart.data.datasets[3].backgroundColor = `rgba(${cssVar("--bs-chart-fill-green")}, 0.2)`;
		userAcquisitionChart.config.options.scales.y.grid.color = cssVar("--bs-chart-grid-green");
		userAcquisitionChart.config.options.scales.x.ticks.color = cssVar("--bs-chart-ticks-green");
		userAcquisitionChart.config.options.scales.y.ticks.color = cssVar("--bs-chart-ticks-green");

		setTimeout(() => {
			userAcquisitionChart.update();
		});
	}
};
