import { activeUserChartUpdate } from "../../views/analytics/activeUsers";
import { siteOverviewChartUpdate } from "../../views/analytics/siteOverview";
import { userAcquisitionChartUpdate } from "../../views/analytics/userAcquisition";
import { visitsBySourceUpdate } from "../../views/analytics/visitsBySource";
import { chartsUpdate } from "../../views/charts/charts";
import { totalSalesChartUpdate } from "../../views/e-commerce/total-sales/totalSales";
import { totalOrdersChartUpdate } from "../../views/e-commerce/totalOrders";

const TOGGLE_EL = document.getElementById("color-mode-toggle");

// Some of the charts need to updated on color mode change
const updateWidgets = () => {
	visitsBySourceUpdate();
	activeUserChartUpdate();
	siteOverviewChartUpdate();
	userAcquisitionChartUpdate();
	totalSalesChartUpdate();
	totalOrdersChartUpdate();
	chartsUpdate();
};

export const colorMode = () => {
	if (TOGGLE_EL) {
		let color = localStorage.getItem("color-mode") || "light";

		// Set color mode on toggle click
		TOGGLE_EL.addEventListener("click", () => {
			color === "dark" ? (color = "light") : (color = "dark");
			localStorage.setItem("color-mode", color);
			document.documentElement.setAttribute("data-bs-theme", color);

			updateWidgets();
		});
	}
};
