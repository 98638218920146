import "chartjs-adapter-moment";
import Chart from "chart.js/auto";
import { chartTicks, generateData, CHART_TOOLTIP } from "../../vendors/chart";
import { COLORS, cssVar } from "../../utils";

const CHART_WRAPPER = document.getElementById("chart-site-overview");
let siteOverviewChart;

export const siteOverview = () => {
	//---------------------------------------------------------
	// Chart
	//---------------------------------------------------------
	const CHART_DATA = {
		labels: ["10/11", "10/12", "10/13", "10/14", "10/15", "10/16", "10/17", "10/18", "10/19", "10/20"],
		datasets: [
			{
				label: "Last Week",
				data: generateData(150, 200),
				tension: 0.4,
				borderColor: cssVar("--bs-chart-fill-navy"),
				borderDash: [2, 2],
				borderWidth: 1.5,
				pointRadius: 0,
				pointHoverRadius: 3,
				pointBackgroundColor: "transparent",
				pointBorderColor: cssVar("--bs-chart-fill-navy"),
				pointHoverBorderColor: cssVar("--bs-chart-fill-navy"),
				pointHoverBackgroundColor: cssVar("--bs-chart-fill-navy"),
			},
			{
				label: "This Week",
				data: generateData(145, 205),
				tension: 0.4,
				borderColor: cssVar("--bs-chart-fill-navy"),
				borderWidth: 1.5,
				pointRadius: 0,
				pointHoverRadius: 3,
				pointBackgroundColor: "transparent",
				pointBorderColor: cssVar("--bs-chart-fill-navy"),
				pointHoverBorderColor: cssVar("--bs-chart-fill-navy"),
				pointHoverBackgroundColor: cssVar("--bs-chart-fill-navy"),
			},
		],
	};

	const CHART_CONFIG = {
		type: "line",
		data: CHART_DATA,
		options: {
			maintainAspectRatio: false,
			interaction: {
				mode: "index",
				intersect: false,
			},
			layout: {
				padding: {
					left: -8,
				},
			},
			responsive: true,
			plugins: {
				legend: {
					display: false,
				},
				title: {
					display: false,
				},
				tooltip: {
					...CHART_TOOLTIP,
					callbacks: {
						label: (tooltipItem, data) => {
							return tooltipItem.formattedValue + "K";
						},
					},
				},
			},
			scales: {
				x: {
					border: {
						display: false,
					},
					grid: {
						display: false,
					},
					ticks: {
						...chartTicks(5, cssVar("--bs-chart-ticks-navy")),
						autoSkip: true,
						maxRotation: 0,
						maxTicksLimit: 5,
						labelOffset: 25,
					},
				},
				y: {
					border: {
						display: false,
					},
					grid: {
						color: cssVar("--bs-chart-grid-navy"),
						tickLength: 0,
					},
					ticks: {
						...chartTicks(10, cssVar("--bs-chart-ticks-navy")),
						maxTicksLimit: 7,
						min: 130,
						max: 220,
						callback: (label) => {
							return label + "K";
						},
					},
				},
			},
		},
	};

	if (CHART_WRAPPER) {
		siteOverviewChart = new Chart(CHART_WRAPPER, CHART_CONFIG);
	}

	//---------------------------------------------------------
	// Data
	//---------------------------------------------------------
	let list = "";
	const LIST_WRAPPER = document.getElementById("list-site-overview");
	const LIST_DATA = [
		{
			icon: "user-plus",
			type: "New Users",
			value: 3656,
			change: 67.5,
			up: true,
		},
		{
			icon: "user-sound",
			type: "No. of Sessions per User",
			value: 2.54,
			change: 55.3,
			up: true,
		},
		{
			icon: "app-window",
			type: "Pageviews",
			value: 86542,
			change: 24.6,
			up: false,
		},
		{
			icon: "clock",
			type: "Avg. Session Duration",
			value: "00:01:45",
			change: 23.7,
			up: false,
		},
		{
			icon: "browsers",
			type: "Average Page Depth",
			value: "2.31%",
			change: 89.4,
			up: true,
		},
	];

	if (LIST_WRAPPER) {
		LIST_DATA.forEach((item, index) => {
			list += `<div class="d-flex align-items-center pt-3 ${index !== LIST_DATA.length - 1 ? "border-bottom pb-3" : ""}">
						<div class="flex-grow-1">
							<i class="ph me-2 fs-4 ph-${item.icon}"></i>
							${item.type}
						</div>
						<div class="fw-medium">${item.value}</div>
						<div class="fs-7 ms-4 d-none d-sm-flex align-items-center justify-content-end w-14 ${item.up ? "text-success" : "text-danger"}">
							${item.change}%

							<i class="ph fs-5 ms-1 ${item.up ? "ph-arrow-circle-up" : "ph-arrow-circle-down"}"></i>
						</div>
					</div>`;
		});

		LIST_WRAPPER.innerHTML = list;
	}
};

// Reload chart to match dark/light mode when switched
// This function will be used in `colorMode.js`
export const siteOverviewChartUpdate = () => {
	if (CHART_WRAPPER) {
		siteOverviewChart.data.datasets[0].borderColor = cssVar("--bs-chart-fill-navy");
		siteOverviewChart.data.datasets[1].borderColor = cssVar("--bs-chart-fill-navy");
		siteOverviewChart.config.options.scales.x.grid.color = cssVar("--bs-chart-grid-navy");
		siteOverviewChart.config.options.scales.y.grid.color = cssVar("--bs-chart-grid-navy");
		siteOverviewChart.config.options.scales.x.ticks.color = cssVar("--bs-chart-ticks-navy");
		siteOverviewChart.config.options.scales.y.ticks.color = cssVar("--bs-chart-ticks-navy");

		setTimeout(() => {
			siteOverviewChart.update();
		});
	}
};
