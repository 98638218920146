import images from "../../../img/os/*.svg";

export const os = () => {
	const WRAPPER = document.getElementById("list-os");
	const DATA = [
		{
			os: "Windows",
			value: 32343,
			percentage: 35.4,
			color: "bg-primary",
			icon: "windows",
			up: false,
		},
		{
			os: "Linux",
			value: 29812,
			percentage: 32.7,
			color: "bg-warning",
			icon: "linux",
			up: true,
		},
		{
			os: "MacOS",
			value: 23215,
			percentage: 22.6,
			color: "bg-info",
			icon: "macos",
			up: true,
		},
		{
			os: "Android",
			value: 12654,
			percentage: 11.4,
			color: "bg-success",
			icon: "android",
			up: false,
		},
		{
			os: "FreeBSD",
			value: 8673,
			percentage: 10.8,
			color: "bg-danger",
			icon: "freebsd",
			up: false,
		},
	];

	if (WRAPPER) {
		let list = "";
		DATA.forEach((item) => {
			list += `<div class="d-flex align-items-center flex-wrap">
						<div class="flex-grow-1">
							<img class="w-4 me-3" src="${images[item.icon]}" alt="${item.os}" />
							${item.os}
						</div>
						<div class="fw-medium">${item.value}</div>
						<div class="ms-4 text-end d-none d-sm-inline w-16 ${item.up ? "text-success" : "text-danger"}">
                            ${item.percentage}%
                            <i class="ph fs-5 ms-1 ${item.up ? "ph-arrow-circle-up" : "ph-arrow-circle-down"}"></i>
						</div>

                        <div class="progress w-100 my-3 h-0.5" role="progressbar" aria-valuenow="${item.percentage}" aria-valuemin="0" aria-valuemax="100">
							<div class="progress-bar ${item.color}" style="width: ${item.percentage}%"></div>
						</div>
					</div>`;
		});

		WRAPPER.innerHTML = list;
	}
};
