const _temp0 = require("../../../img/regions/usa/ca.svg");
const _temp1 = require("../../../img/regions/usa/fl.svg");
const _temp2 = require("../../../img/regions/usa/id.svg");
const _temp3 = require("../../../img/regions/usa/ms.svg");
const _temp4 = require("../../../img/regions/usa/nc.svg");
const _temp5 = require("../../../img/regions/usa/ny.svg");
const _temp6 = require("../../../img/regions/usa/oh.svg");
const _temp7 = require("../../../img/regions/usa/tx.svg");
module.exports = {
  "ca": _temp0,
  "fl": _temp1,
  "id": _temp2,
  "ms": _temp3,
  "nc": _temp4,
  "ny": _temp5,
  "oh": _temp6,
  "tx": _temp7
}