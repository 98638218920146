export const sessionsByChannels = () => {
	const WRAPPER = document.getElementById("list-sessions-by-channels");
	const DATA = [
		{
			type: "Searches",
			value: "12,762",
			percentage: 88,
			color: "info",
		},
		{
			type: "Social Media",
			value: "4,456",
			percentage: 75,
			color: "purple",
		},
		{
			type: "Emails",
			value: "856",
			percentage: 54,
			color: "success",
		},
		{
			type: "Direct Visits",
			value: "10,456",
			percentage: 80,
			color: "danger",
		},
		{
			type: "Referrals",
			value: "354",
			percentage: 33,
			color: "warning",
		},
		{
			type: "Templates",
			value: "9736",
			percentage: 68,
			color: "primary",
		},
		{
			type: "Facebook",
			value: "3954",
			percentage: 43,
			color: "indigo",
		},
		{
			type: "x.com",
			value: "1982",
			percentage: 21,
			color: "current",
		},
		{
			type: "Tiktok",
			value: "783",
			percentage: 9,
			color: "orange",
		},
		{
			type: "Instagram",
			value: "672",
			percentage: 6,
			color: "pink",
		},
	];

	if (WRAPPER) {
		let list = "";
		DATA.forEach((item) => {
			list += `<div>
						<svg viewBox="0 0 28 28" class="w-16 mb-4 radial-chart text-${item.color}">
							<path d="M0.5 14C0.5 8.39643 1.21256 5.09455 3.15355 3.15355C5.09455 1.21256 8.39643 0.5 14 0.5C19.6036 0.5 22.9055 1.21256 24.8464 3.15355C26.7874 5.09455 27.5 8.39643 27.5 14C27.5 19.6036 26.7874 22.9055 24.8464 24.8464C22.9055 26.7874 19.6036 27.5 14 27.5C8.39643 27.5 5.09455 26.7874 3.15355 24.8464C1.21256 22.9055 0.5 19.6036 0.5 14Z" stroke="currentColor"/>
							<path stroke-dasharray="${item.percentage}, 100" d="M0.5 14C0.5 8.39643 1.21256 5.09455 3.15355 3.15355C5.09455 1.21256 8.39643 0.5 14 0.5C19.6036 0.5 22.9055 1.21256 24.8464 3.15355C26.7874 5.09455 27.5 8.39643 27.5 14C27.5 19.6036 26.7874 22.9055 24.8464 24.8464C22.9055 26.7874 19.6036 27.5 14 27.5C8.39643 27.5 5.09455 26.7874 3.15355 24.8464C1.21256 22.9055 0.5 19.6036 0.5 14Z" stroke="currentColor"/>
							<text x="50%" y="53%" text-anchor="middle" dominant-baseline="middle">${item.percentage}%</text>
						</svg>

						<div class="mt-n2 fs-7 text-body-secondary">${item.type}</div>
						<div class="fw-medium">${item.value}</div>
                    </div>`;
		});

		WRAPPER.innerHTML = list;
	}
};
