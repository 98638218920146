import { activeUsers } from "./activeUsers";
import { averageVisitDuration } from "./averageVisitDurarion";
import { browserBounceRate } from "./browserBounceRate";
import { deviceSessions } from "./deviceSessions";
import { os } from "./os";
import { sessionsByChannels } from "./sessionsBychannels";
import { sessionsByCountry } from "./sessionsByCountry";
import { siteOverview } from "./siteOverview";
import { userAcquisition } from "./userAcquisition";
import { visitsBySource } from "./visitsBySource";

export const analytics = () => {
	sessionsByCountry();
	activeUsers();
	visitsBySource();
	averageVisitDuration();
	userAcquisition();
	browserBounceRate();
	deviceSessions();
	sessionsByChannels();
	os();
	siteOverview();
};
