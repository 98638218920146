const _temp0 = require("../../../img/os/android.svg");
const _temp1 = require("../../../img/os/freebsd.svg");
const _temp2 = require("../../../img/os/linux.svg");
const _temp3 = require("../../../img/os/macos.svg");
const _temp4 = require("../../../img/os/others.svg");
const _temp5 = require("../../../img/os/windows.svg");
module.exports = {
  "android": _temp0,
  "freebsd": _temp1,
  "linux": _temp2,
  "macos": _temp3,
  "others": _temp4,
  "windows": _temp5
}